<!--！！！该页面废弃使用。字段修改和多语言已不支持该页面，重新启用该页面需要修正接口字段和多语言校准-->
<template>
    <v-q-r-code-template>
        <div>
            <span class="via-mobile360-logo via-icon"></span>
        </div>
        <div>
            <span class="via-title">Welcome to VIA Fleet</span>
        </div>
        <el-form :model="user" ref="user" :rules="rules">
            <el-form-item prop="loginId" class="login-id-input">
                <input type="text"  placeholder="Login ID" v-model="user.loginId" ></input>
            </el-form-item>
            <el-form-item prop="password" class="password-input">
                <input type="text"  placeholder="Password" v-model="user.password"></input>
            </el-form-item>
        </el-form>
        <!--<div class="login-id-input">-->
            <!--<input type="text"  placeholder="Login ID" v-model="loginId" ></input>-->
            <!--<div class="error"><span v-show="loginIdIsNull">please input LoginId</span></div>-->
        <!--</div>-->
        <!--<div class="password-input">-->
            <!--<input type="text"  placeholder="Password" v-model="password"></input>-->
            <!--<div class="error"><span v-show="passwordIsNull">please input password</span></div>-->
        <!--</div>-->
        <div class="login-button">
            <button @click="login">Login</button>
        </div>
    </v-q-r-code-template>
</template>

<script>
    import vQRCodeTemplate from "./QRCodeTemplate"
    export default {
        name: "Login",
        components:{
            vQRCodeTemplate
        },
        data(){
            return{
                user: {
                    loginId: '',
                    password: '',
                },
                rules: {
                    loginId: [
                        {required: true, message: 'Please input user name or mobile number', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: 'Please input password', trigger: 'blur'}
                    ]
                }
            }
        },
        methods:{
            login(){
                this.$refs['user'].validate((valid) => {
                    if (valid) {
                        //this.$router.push("/monitor")
                        // alert('submit!');
                        this.$serverApi.auth.login(this.user.loginId,this.user.password,()=>{
                            this.$router.push("/binding/device").catch();
                        });

                    } else {
                        return false;
                    }
                });
            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .qrCode-content {
        padding-top: 1.6rem;
    }

    .via-icon {
        font-size: 2rem;
    }

    .via-title {
        font-size: 0.5rem;

    }

    .login-id-input,.password-input{
        width: 80%;
        margin: 0 auto;
    }
    .login-id-input{
        margin-top:1rem;
    }
    .password-input{
        margin-top:0.5rem
    }
    .login-id-input input,.password-input input{
        border-radius: 0.1rem;
        height: 0.9rem;
        width: 100%;
        font-size: 0.32rem;
        padding:0 0.15rem;
        color:#909090;
        border: 0;
        outline-style: none ;
    }

    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #b5b5b5;;
    }
    input::-moz-placeholder { /* Firefox 19+ */
        color: #b5b5b5;;
    }
    input:-ms-input-placeholder { /* IE 10+ */
        color: #b5b5b5;;
    }
    input:-moz-placeholder { /* Firefox 18- */
        color: #b5b5b5;;
    }


    .login-id-input input:focus,.password-input input:focus{
        border-color: #000000;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0, 0, 0, 0.6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0, 0, 0, 0.6)
    }

    .login-button{
        margin-top: 0.7rem;
    }



    @media (min-height:750px) and (orientation:landscape){
        .qrCode-content {
            padding-top: 0.4rem;
        }

        .login-id-input{
            margin-top:0.4rem;
        }
        .password-input{
            margin-top:0.4rem
        }

        .login-button{
            margin-top: 0.4rem;
        }
    }
</style>
<style>
    .qrCode-content .el-form-item__error{
        font-size: 0.24rem;
    }
</style>
