<template>
    <div class="qrCode-content">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "QrCodeTemplate",
        created() {
            this.setHtmlFontSize()
        },
        watch: {
            '$store.getters.screen'() {
                this.setHtmlFontSize()
            }
        },
        methods: {
            setHtmlFontSize() {
                const htmlDom = document.getElementsByTagName('html')[0];
                let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
                if (htmlWidth >= 1024) {
                    htmlWidth = 1024;
                }
                if (htmlWidth <= 320) {
                    htmlWidth = 320;
                }
                htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
            }
        }
    }
</script>

<style scoped>
 @import "~@/assets/stylus/color.styl"
    .qrCode-content {
        font-size: 0.48rem;
        font-family: 'Microsoft YaHei UI Light';
    }

    .qrCode-content {
        background-color: $color_blue_first;
        color: white;
        text-align: center;
        /*padding-top: 1.6rem;*/
        height: 100%;
        position: relative;
    }
    .login-button button{
        border-radius: 0.1rem;
        border: 0.02rem solid white;
        width: 50%;
        height: 0.9rem;
        background-color:  $color_blue_first;
        color: white;
        font-size: 0.36rem;
        cursor: pointer;
        outline: none;
    }

    .login-button button:hover{
        background-color: #3a4cd1;
    }


</style>